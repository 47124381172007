<template>
  <div v-if="loading">
    <Loader />
  </div>
  <div v-if="!loading" class="card shadow-sm">
    <form id="formItems" class="needs-validation" novalidate>
      <div class="card-header">
        <h3 class="card-title" style="font-size: 20px; font-weight: bold">
          {{ $t("editwarehouse") }}
        </h3>
      </div>
      <!-- form -->
      <div class="card-body">
        <!--{{}} -->
        <div class="row d-flex justify-content-center mb-4">
          <div class="col-sm-5">
            <label for="validationTooltip01" class="required form-label col-sm-10"
              >{{ $t("company") }}</label
            >
            <select
              :style="
                isSubmit
                  ? `border-color: #ced4da;padding-right: 0.75rem;background: none;${ccsSelect}`
                  : ''
              "
              class="form-select"
              v-model="form.companyId"
              disabled
              required
            >
              <option
                v-for="company in companies"
                :key="company"
                :value="company.id"
              >
                {{ company.company_code }} - {{ company.company_name }}
              </option>
            </select>
          </div>
          <!-- </div>
        <div class="row d-flex justify-content-center mb-4"> -->
          <div class="col-sm-5">
            <label for="validationTooltip01" class="required form-label col-sm-10"
              >{{ $t("branch") }}</label
            >
            <select v-model="form.branchId" class="form-select" required>
              <div class="invalid-feedback text-end">กรุณากรอก</div>
              <option
                v-for="branch in branches"
                :key="branch"
                :value="branch.id"
              >
                {{ branch.code }} {{ branch.short_name }} {{ branch.name }}
              </option>
            </select>
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>
        <div class="row d-flex justify-content-center mb-4">
          <div class="col-sm-5">
            <label
              for="validationTooltip01"
              class="required form-label col-sm-10"
              >{{ $t("warehousecode") }}</label
            >
            <!-- oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" -->
            <input
              v-model="form.warehouse_code"
              :maxlength="form.companyId == '1' ? 3 : ''"
              type="text"
              class="form-control"
              id="validationTooltip01"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
          <!-- </div>
        <div class="row d-flex justify-content-center mb-4"> -->
          <div class="col-sm-5">
            <label
              for="validationTooltip01"
              class="required form-label col-sm-10"
              >{{ $t("initials") }}</label
            >
            <input
              v-model="form.warehouse_short_name"
              type="text"
              class="form-control"
              id="validationTooltip01"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>
        <div class="row d-flex justify-content-center mb-4">
          <div class="col-sm-5">
            <label
              for="validationTooltip01"
              class="required form-label col-sm-10"
              >{{ $t("warehousename") }}</label
            >
            <input
              v-model="form.warehouse_full_name"
              type="text"
              class="form-control"
              id="validationTooltip01"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
          <!-- </div>
        <div class="row d-flex justify-content-center mb-4"> -->
          <div class="col-sm-5">
            <label for="validationTooltip01" class="required form-label col-sm-10"
              >{{ $t("warehousetype") }}</label
            >
            <select
              v-model="form.warehouse_type"
              
              class="form-select"
              required
            >
            <div class="invalid-feedback text-end">กรุณากรอก</div>
              <option value="คลังหลัก">จอมธนา</option>
              <option value="คลังรอง">agent</option>
              <option value="คลังรอง">อื่นๆ</option>
            
            </select>
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>
        <div class="row d-flex justify-content-center mb-4">
          <div class="col-sm-5">
            <label
              for="validationTooltip01"
              class="required form-label col-sm-10"
              >{{ $t("telephone") }}</label
            >
            <input
              v-model="form.tel"
              type="text"
              class="form-control"
              id="validationTooltip01"
              v-mask-number
              maxlength="10"
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
          <div class="col-sm-5">
            <label
              for="validationTooltip01"
              class="required form-label col-sm-10"
              >{{ $t("fax") }}</label
            >
            <input
              v-model="form.fax"
              type="text"
              class="form-control"
              id="validationTooltip01"
              v-mask-number
              required
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>
         <div class="row d-flex justify-content-center mb-4">
          <div class="col-sm-5">
            <label
              for="validationTooltip01"
              class="required form-label col-sm-10"
              >ที่อยู่</label
            >
            <input
              v-model="form.address"
              type="text"
              class="form-control"
              id="validationTooltip01"
              required
              
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
          <div class=" col-sm-5">
            <label
              for="validationTooltip01"
              class="required form-label col-sm-10"
              >ตำบล</label
            >
            <input
              v-model="form.subdistrict"
              type="text"
              class="form-control"
              id="validationTooltip01"
              required
              
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>

         <div class="row d-flex justify-content-center mb-4">
          <div class="col-sm-5">
            <label
              for="validationTooltip01"
              class="required form-label col-sm-10"
              >อำเภอ</label
            >
            <input
              v-model="form.subdistrict"
              type="text"
              class="form-control"
              id="validationTooltip01"
              required
              
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
          <div class="col-sm-5">
            <label
              for="validationTooltip01"
              class="required form-label col-sm-10"
              >จังหวัด</label
            >
            <input
              v-model="form.province"
              type="text"
              class="form-control"
              id="validationTooltip01"
              required
              
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>

        <div class="row d-flex justify-content-center mb-4">
          <div class="col-sm-5">
            <label
              for="validationTooltip01"
              class="required form-label col-sm-10"
              >รหัสไปรษณีย์</label
            >
            <input
              v-model="form.zipcode"
              type="text"
              class="form-control"
              id="validationTooltip01"
              required
              
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
          <div class="col-sm-5">
            <label
              for="validationTooltip01"
              class=" form-label col-sm-10"
              >{{ $t("latitude") }}</label
            >
            <input
              v-model="form.lat"
              type="text"
              class="form-control"
              id="validationTooltip01"
              
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>



          
        </div>
        

         <div class="row d-flex justify-content-center mb-4">
          
          <div class="col-sm-5">
            <label
              for="validationTooltip01"
              class=" form-label col-sm-10"
              >{{ $t("longitude") }}</label
            >
            <input
              v-model="form.lng"
              type="text"
              class="form-control"
              id="validationTooltip01"
              
            />
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
          <div class="col-sm-5">
            <label
              for="validationTooltip01"
              class=" form-label col-sm-10"
              ></label
            >
            <!-- <input
              v-model="form.district"
              type="text"
              class="form-control"
              id="validationTooltip01"
              
            /> -->
            <div class="invalid-feedback text-end">กรุณากรอก</div>
          </div>
        </div>
        <div class="row d-flex justify-content-center mb-4">
          <div class="col-sm-10 mt-6 mb-6">
            <label
              class="
                form-check form-check-sm form-check-custom form-check-solid
                me-5
              "
            >
              <el-checkbox
                v-model="form.is_sale_warehouse"
                type="checkbox"
                :value="true"
              />
              <span class="form-check-label"> {{ $t("warehouses") }} </span>
            </label>
          </div>
        </div>
      </div>

      <div class="card-header">
        <h3 class="" style="font-size: 20px; font-weight: bold">{{ $t("contact") }}</h3>
      </div>

      <div class="card-body">
        <div class="row d-flex justify-content-center mb-4 mt-4">
          <label for="validationTooltip01" class="form-label col-sm-2"
            >{{ $t("name") }}</label
          >
          <label for="validationTooltip01" class="form-label col-sm-2"
            >{{ $t("position") }}</label
          >
          <label for="validationTooltip01" class="form-label col-sm-2"
            >{{ $t("telephone") }}</label
          >
          <label for="validationTooltip01" class="form-label col-sm-4"
            >{{ $t("email") }}</label
          >
        </div>
        <div
          class="row d-flex justify-content-center mb-6"
          v-for="(contact, index) in contacts"
          :key="contact"
        >
          <div class="col-sm-2">
            <input
              v-model="contact.name"
              type="text"
              class="form-control"
              id="validationTooltip01"
            />
          </div>
          <div class="col-sm-2">
            <input
              v-model="contact.position"
              type="text"
              class="form-control"
              id="validationTooltip01"
            />
          </div>
          <div class="col-sm-2">
            <input
              v-model="contact.tel"
              type="text"
              class="form-control"
              id="validationTooltip01"
            />
          </div>
          <div class="col-sm-2">
            <input
              v-model="contact.email"
              type="text"
              class="form-control"
              id="validationTooltip01"
            />
          </div>
          <div class="col-sm-2">
            <button
              type="button"
              class="btn btn-light btn-danger me-2"
              style="font-size: 14px; font-weight: bold"
              @click="removeContact(index)"
            >
              -
            </button>
            <button
              type="button"
              class="btn btn-light btn-primary"
              style="font-size: 14px; font-weight: bold"
              @click="addContact(index)"
            >
              +
            </button>
          </div>
        </div>
      </div>

      <div class="card-header">
        <h3 class="" style="font-size: 20px; font-weight: bold">{{ $t("managevan") }}</h3>
      </div>

      <div class="card-body">
        <div class="card-toolbar row justify-content-center">
          <div
            class="col-sm-5"
            style="
              border: solid lightgray 0.5px;
              padding: 20px;
              border-radius: 10px;
            "
          >
            <b><u>VAN ทั้งหมด</u></b>
            <div>
              <el-checkbox
                type="checkbox"
                v-model="allLeftCheckbox"
                @change="checkAllitemsleftArray()"
              />
              <span class="form-check-label"> เลือกทั้งหมด </span>
            </div>
            <div
              v-for="van in itemsleftArray"
              :key="van"
              class="col-sm-12 me-1"
            >
              <label
                class="
                  form-check form-check-sm form-check-custom form-check-solid
                  me-5
                  mb-2
                  mt-2
                "
              >
                <el-checkbox type="checkbox" v-model="van.isSelected" />
                <span class="form-check-label">
                  {{ van.van_name }}
                </span>
              </label>
            </div>
          </div>
          <div class="col-sm-1" style="align-self: center; text-align: center">
            <button
              type="button"
              class="btn btn-light btn-primary"
              style="font-size: 14px; font-weight: bold"
              @click="appendRightArray()"
            >
              <i class="fas fa-arrow-right"></i>
            </button>
            <button
              type="button"
              class="btn btn-light btn-danger"
              style="font-size: 14px; font-weight: bold; margin-top: 10px"
              @click="appendLeftArray()"
            >
              <i class="fas fa-arrow-left"></i>
            </button>
          </div>
          <div
            class="col-sm-5 ms-1"
            style="
              border: solid lightgray 0.5px;
              padding: 20px;
              border-radius: 10px;
            "
          >
            <b><u>VAN ที่เลือก</u></b>
            <div>
              <el-checkbox
                type="checkbox"
                v-model="allRightCheckbox"
                @change="checkAllitemsrightArray()"
              />
              <span class="form-check-label"> เลือกทั้งหมด </span>
            </div>
            <div
              v-for="item in itemsrightArray"
              :key="item"
              class="col-sm-12 me-1"
            >
              <label
                class="
                  form-check form-check-sm form-check-custom form-check-solid
                  me-5
                  mb-2
                  mt-2
                "
              >
                <el-checkbox type="checkbox" v-model="item.isSelected" />
                <span class="form-check-label">
                  {{ item.van_name }}
                </span>
              </label>
            </div>
          </div>
          <div></div>
        </div>
      </div>

      <hr class="pt-0 mt-0" style="color: LightGrey" />

      <footer class="m-6">
        <div class="card-toolbar row justify-content-center">
          <div class="col-sm-10">
            <button
              type="submit"
              class="btn btn-sm btn-light btn-primary ms-3 me-2"
              style="font-size: 14px; font-weight: bold"
              @click="submit"
              :disabled="loadingUpdate"
            >
              <div
                v-if="loadingUpdate"
                class="spinner-grow spinner-grow-sm text-light"
                role="status"
              ></div>
              {{ $t("save") }}
            </button>
            <button
              type="button"
              class="btn btn-sm btn-light"
              style="font-size: 14px; font-weight: bold"
              @click="cancel"
            >
            {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </footer>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import Loader from "../../components/loaderUpdate.vue";
import whApi from "@/api/warehouse/";
import companyApi from "@/api/user/";

export default {
  setup() {
    document.title = "bem-document-demo แก้ไขคลังสินค้า";
  },
  components: {
    Loader,
  },
  data() {
    return {
      loading: false,
      loadingUpdate: false,
      isSubmit: false,
      form: {
        companyId: parseInt(localStorage.getItem("companyId")),
        // warehouse_code: "",
        // warehouse_name: "",
        // selectMainWarehouse: "",
      },
      companies: [],
      branches: [],
      vans: [],
      itemsleftArray: [],
      itemsrightArray: [],
      selectedVan: [],
      unSelectedVan: [],
      contacts: [],
      ccsSelect: `background-size: 16px 12px;background-position: right 1rem center;background-repeat: no-repeat;background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%237E8299' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e")`,
      allLeftCheckbox: false,
      allRightCheckbox: false,
    };
  },

  async created() {
    this.contacts = [];
    this.getAllCompany();
    this.getAllBranch();
    await this.getOne();
    await this.getAllMWarehouseVan();
    await this.getAllVan();
    await this.getAllContact();
    console.log("this.itemsrightArray", this.itemsrightArray);
  },
  methods: {
    checkAllitemsleftArray() {
      if (this.allLeftCheckbox) {
        this.itemsleftArray.forEach((element) => {
          element.isSelected = true;
        });
      } else {
        this.itemsleftArray.forEach((element) => {
          element.isSelected = false;
        });
      }
    },
    checkAllitemsrightArray() {
      if (this.allRightCheckbox) {
        this.itemsrightArray.forEach((element) => {
          element.isSelected = true;
        });
      } else {
        this.itemsrightArray.forEach((element) => {
          element.isSelected = false;
        });
      }
    },
    appendRightArray() {
      this.itemsleftArray.forEach((element) => {
        if (element.isSelected == true) {
          let a = {
            ...element,
          };
          a.isSelected = false;
          console.log("aaaaaaaaaa", a);
          this.itemsrightArray.push(a);
        }
      });
      this.itemsleftArray = this.itemsleftArray.filter(
        (item) => item.isSelected !== true
      );
      this.allLeftCheckbox = false;
    },
    appendLeftArray() {
      this.itemsrightArray.forEach((element) => {
        if (element.isSelected == true) {
          let a = {
            ...element,
          };
          a.isSelected = false;
          this.itemsleftArray.push(a);
        }
        this.itemsrightArray = this.itemsrightArray.filter(
          (item) => item.isSelected !== true
        );
      });
      this.allRightCheckbox = false;
    },

    async addVanAPI() {
      let createResponse = [];

      this.loading = true;
      this.itemsrightArray.forEach(async (element) => {
        let data = {
          vanId: element.id,
          warehouseId: this.form.id,
        };
        createResponse = await whApi.mWarehouseVans.create(data);
      });

      this.loading = false;
      // }
    },
    async getAllMWarehouseVan() {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.mWarehouseVans.getQuery(this.form.id);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        let array = responseData.data;
        console.log("array", array);
        array.forEach((element) => {
          this.itemsrightArray.push(element.van);
        });
        console.log("this.itemsrightArray", this.itemsrightArray);
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    async getAllContact() {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.contact.getAll(this.form.id);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.contacts = responseData.data;

        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    async getAllVan() {
      this.loading = true;
      let responseData = [];
      try {
        // responseData = await whApi.van.getAll();
        responseData = await whApi.van.getAvalibleVans(this.form.id,this.form.branchId);
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        // let getVan = responseData.data;
        this.itemsleftArray = responseData.data;

        // console.log("getVan left", getVan);
        // console.log("itemsrightArray left", this.itemsrightArray);
        // this.itemsrightArray.forEach((element) => {
        //   this.itemsleftArray = getVan.filter((val) => val.id !== element.id);
        // });

        console.log("this.itemsleftArray", this.itemsleftArray);
        this.loading = false;
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    async getAllCompany(method) {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await companyApi.company.getAll();
      } catch (error) {
        console.log(error);
      }
      console.log("responseData", responseData.data);
      if (responseData.response_status === "SUCCESS") {
        this.companies = responseData.data;

        this.loading = false;
        // if (method === 1) {
        //   await this.$router.push({
        //     query: {
        //       page: this.$route.query.page,
        //       get: 1,
        //     },
        //   });
        // }
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    async getAllBranch(method) {
      this.loading = true;
      let responseData = [];
      try {
        responseData = await whApi.branch.getAll();
      } catch (error) {
        console.log(error);
      }
      if (responseData.response_status === "SUCCESS") {
        this.branches = responseData.data;

        this.loading = false;
        if (method === 1) {
          await this.$router.push({
            query: {
              page: this.$route.query.page,
              get: 1,
            },
          });
        }
      } else {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "เกิดข้อผิดพลาด",
          text: "ดึงข้อมูลไม่สำเร็จ กรุณาตรวจสอบ SERVER",
        });
      }
    },
    addContact(index) {
      console.log("index", index);
      this.contacts.splice(index + 1, 0, {
        name: "",
        position: "",
        tel: "",
        email: "",
      });
    },
    removeContact(index) {
      this.contacts.splice(index, 1);
    },
    async getOne() {
      this.loading = true;
      let getOneResponse = {};
      try {
        getOneResponse = await whApi.warehouse.getOne(this.$route.query.id);
      } catch (error) {
        console.log(error);
      }
      if (getOneResponse.response_status === "SUCCESS") {
        this.form = {
          ...getOneResponse.data,
          // selectMainWarehouse: getOneResponse.data.warehouse_main ? "1" : "2",
        };
        this.loading = false;
      }
    },
    async updateContacts(warehouseId) {
      let data = {};
      this.contacts.forEach(async (element) => {
        data = { ...element, warehouseId: warehouseId };
        if (element.id) {
          await whApi.contact.update(element.id, data);
        } else {
          await whApi.contact.create(data);
        }
      });

      // const data = {
      //   ...this.contacts,
      //   warehouseId: warehouseId,
      // };
    },
    async submit() {
      let updateResponse = [];

      await this.validateForm();
      await this.checkFormIsEmpty();
      this.isSubmit = true;
      let warehoseVans = [];
      this.itemsrightArray.forEach((element) => {
        console.log("element", element);
        let dataVans = {
          vanId: element.id,
          warehouseId: this.form.id,
        };
        warehoseVans.push(dataVans);
      });
      this.form = {
        ...this.form,
        contacts: this.contacts,
        warehoseVans: warehoseVans,
        // warehouse_main: this.form.selectMainWarehouse === "1" ? true : false,
        // status: this.form.status === "2" ? "0" : this.form.status,
        companyId: parseInt(localStorage.getItem("companyId")),
      };
      if (this.isValid) {
        // await this.addVanAPI();
        this.loadingUpdate = true;
        updateResponse = await whApi.warehouse.update(
          this.$route.query.id,
          this.form
        );
        if (updateResponse.response_status === "SUCCESS") {
          // await this.updateContacts(this.$route.query.id);
          Swal.fire({
            icon: "success",
            title: `แก้ไขสำเร็จ`,
            showConfirmButton: false,
            timer: 1500,
          }).then(() => {
            this.$router.push({
              path: "/warehouse/WH1",
              query: {},
            });
          });
          this.loadingUpdate = false;
        } else {
          Swal.fire({
            icon: "error",
            title: `${updateResponse.message}`,
            showConfirmButton: false,
            timer: 1500,
          });
          this.loadingUpdate = false;
        }
      }
    },

    checkFormIsEmpty() {
      !this.form.warehouse_code
        ? (this.isValid = false)
        : !this.form.warehouse_short_name
        ? (this.isValid = false)
        : !this.form.warehouse_full_name
        ? (this.isValid = false)
        : !this.form.branchId
        ? (this.isValid = false)
        : !this.form.warehouse_type
        ? (this.isValid = false)
        // : !this.form.lat
        // ? (this.isValid = false)
        // : !this.form.lng
        // ? (this.isValid = false)
        : !this.form.tel
        ? (this.isValid = false)
        : !this.form.fax
        ? (this.isValid = false)
        : (this.isValid = true);
    },

    validateForm() {
      let forms = document.querySelectorAll(".needs-validation");
      Array.prototype.slice.call(forms).forEach(function (form) {
        form.addEventListener(
          "submit",
          function (event) {
            if (!form.checkValidity()) {
              event.preventDefault();
              event.stopPropagation();
            }

            form.classList.add("was-validated");
          },
          false
        );
      });
    },

    cancel() {
      this.$router.push({
        path: "/warehouse/WH1",
        query: {},
      });
    },
  },
};
</script>
